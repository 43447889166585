import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

export const oidcConfig: AuthProviderProps = {
    authority: window?.appEnvironment?.OIDC_AUTHORITY,
    client_id: window?.appEnvironment?.OIDC_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback`,
    // post_logout_redirect_uri: `${window.location.origin}/callback`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    response_type: "code",
    scope: "openid profile",
    response_mode: "query",
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    revokeTokensOnSignout: true,
    onSigninCallback: (_user) => {
        window.location.replace("/");
    }
};